import { graphql } from 'gatsby';

import React, { Component } from 'react';
import { get, find } from 'lodash';

import Layout from '../layout/Layout';

import { PageHero, ArticlesSection, Introduction } from '../common/components';

interface Props {
  data: any;
  location: any;
}

class CSR extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const CSRTitle = get(data, 'page.data.title', null);
    const csrDescription = get(data, 'page.data.description', '');
    const body = get(data, 'page.data.body');
    const heroData = find(body, { __typename: 'PrismicCsrBodyHero' });
    const articlesData = find(body, {
      __typename: 'PrismicCsrBodyArticles',
    });

    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {CSRTitle && <Introduction title={CSRTitle.toUpperCase()} description={csrDescription} />}
        {articlesData && (
          <ArticlesSection
            data={articlesData}
            displayFilters={false}
            lang={get(data, 'page.lang', 'en-us')}
          />
        )}
      </Layout>
    );
  }
}

export default CSR;

export const query = graphql`
  query CSRQuery($slug: String!) {
    page: prismicCsr(id: { eq: $slug }) {
      ...CSR
    }
  }
`;
